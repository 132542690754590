<template>
  <el-container>
    <el-header style="display: flex; align-items: center">
      <div style="display: flex; justify-content: flex-start; cursor: default">
        <span style="color: #563279">全部考试</span>
      </div>
    </el-header>
    <el-main style="padding-top: 5px">
      <el-card style="margin-top: 10px; box-shadow: none; border: none">
        <div style="display: flex; align-items: center; width: 100%">
          <div
              style="color: #333333; font-size: 20px; font-weight: bold; flex: 1"
          >
            全部考试
          </div>
          <div style="flex: 2"></div>
        </div>
        <div>

          <el-table
              :data="examList"
              stripe
              style="width: 100%; margin-top: 10px; overflow: auto"
              @selection-change="handleSelectionChange"
          >
            <el-table-column
                label="全选"
                type="selection"
                width="55"
                align="center"
                class="selectebox"
            >
            </el-table-column>

            <el-table-column label="考试名称" min-width="30%">
              <template slot-scope="scope">
                <img
                    src="@/assets/image/file.png"
                    @click="
                    subjectList(
                      scope.row.uuid,
                      scope.row.examName
                    )
                  "
                />
                <span style="margin-left: 10px">{{ scope.row.examName }}</span>
              </template>
            </el-table-column>

            <el-table-column label="开始时间" min-width="30%">
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.beginTime }}</span>
              </template>
            </el-table-column>
            <el-table-column label="结束时间" min-width="30%">
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.endTime }}</span>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页器 -->
          <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currPage"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
          >
          </el-pagination>
        </div>
      </el-card>
    </el-main>
  </el-container>
</template>

<script>
import {getExamListInfo} from "r/admin/videorecord"

export default {
  data() {
    return {
      currPage: 1,
      pageSize: 10,
      examList: [],
      total: 0

    }
  },
  created() {
    this.getexamlist();
  },
  methods: {
    //获取考试列表
    async getexamlist() {
      getExamListInfo({
        pageNum: this.currPage,
        pageSize: this.pageSize
      }).then(res => {
        if (res.data.code !== 200)
          if (res.data.code) this.$message.error(res.data.message)
        this.examList = res.data.data.list
        this.total = res.data.data.total
      })
    },
    //进入下一页
    subjectList(examId, examName) {
      window.localStorage.setItem("examId", examId)
      window.localStorage.setItem("examName", examName)
      this.$router.push({
        name: "admin-videorecord-examlist",
        query: {
          examId: examId,
          examName: examName
        },
      });
    },
    //选择函数触发函数
    handleSelectionChange(val) {

    },
    handleSizeChange(newSize) {
      this.pageSize = newSize;
      this.getexamlist();
    },
    handleCurrentChange(newNum) {
      this.currPage = newNum;
      this.getexamlist();
    },
  },
};
</script>

<style scoped>
/* 去除表格的内边框 */
::v-deep .el-table__row > td {
  border: none;
}

/* 去除最下面的边框线 */
::v-deep .el-table::before {
  height: 0px;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #563279 !important;
  border: 1px solid #563279;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner:hover {
  border: none;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #563279 !important;
  border: 1px solid #563279;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #563279;
  border-radius: 40px;
}

::v-deep .el-pagination.is-background .el-pager li {
  border-radius: 40px;
}

::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background: #dcdfe6;
  border-color: #fff;
}

::v-deep .el-checkbox__inner {
  border: none;
  background: #dcdfe6;
}

::v-deep .el-input__inner {
  border: none;
  background: #fafafa;
}
</style>